import React from 'react';
import logoImage from "../res/images/logo_white.svg";

function Logo({sub}) {
  return (
      <div className={"flex flex-row justify-center"}>
        <img src={logoImage} alt={"White Lucadmin esports logo"} className={"w-20"}/>
        <div className={"text-left m-2"}>
          <p className={"text-xl font-semibold"}>Lucadmin</p>
          <p className={"text-xl font-light"}>{sub}</p>
        </div>
      </div>
  );
}

export default Logo;