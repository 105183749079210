import React from 'react';
import {TimelineOppositeContent} from "@mui/lab";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";

function EsportsTimelineItem({date, title, text}) {
  return (
        <TimelineItem>
          <TimelineOppositeContent color="#a8a29e">
            <span className={"text-sm"}>{date}</span>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div>
              <h2 className={"-mt-2 text-xl font-semibold mb-2 animated-text-white"}>{title}</h2>
              <p className={"animated-text-white"}>{text}</p>
            </div>
          </TimelineContent>
        </TimelineItem>
  );
}

export default EsportsTimelineItem;