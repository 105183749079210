import React from 'react';
import Logo from "../components/Logo";

function Impressum(props) {
  return (
      <div className={"h-screen bg-darkgray text-white p-16 flex flex-col items-center"}>
        <h1 className={"text-center text-3xl"}>Impressum</h1>
        <div className={"mt-10"}><Logo sub={"e.K."}/></div>
        <div className={"mt-10"}><h2 className={"font-bold text-lg"}>Lucadmin e.K.</h2>

          <p className={"mt-3"}>Luca Ilchen<br/>
            Ankoferstraße 7<br/>
            85077 Manching<br/>
            Deutschland<br/></p>


          <p className={"mt-2"}>Telefon: +49 1517 20591112</p>

          <p className={"mt-2"}>Mail: luca.ilchen@lucadmin.de</p>

          <p className={"mt-2"}>Handelsregister: Amtsgericht Ingolstadt, HRA 3690</p><
          /div>
      </div>
  );
}

export default Impressum;