import Lazyload from "react-lazyload";
import {Trans, useTranslation} from "react-i18next";
import shortid from "shortid";

const lngs = {
  en: { nativeName: 'English' },
  de: { nativeName: 'Deutsch' }
};

const MaintenanceScreen = () => {
  const { t, i18n } = useTranslation();

  const getId = () => {
    const id = shortid.generate();
    return id;
  };

  return(
  <div className={"text-center h-screen mx-auto dark:bg-darkgray dark w-full"}>
    <div className={"absolute top-6 left-6"}>
      {Object.keys(lngs).map((lng) => (
          <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => {
            i18n.changeLanguage(lng)
          }}>
          {lngs[lng].nativeName}
          </button>
      ))}
    </div>
    <div className={"grid h-full md:grid-cols-1 md:grid-cols-2 w-full dark "}>
      <div className={"text-left flex flex-col justify-center"}>
        <div key={getId()} className={"mx-20 my-16 md:mt-0 md w-auto md:h-auto flex flex-col"}>
          <h1 className={"text-4xl my-4 mr-auto dark:text-white animated-text"}>
            <Trans i18nKey="maintenance.title">
            Hi. <br/> Schön, dass du zu Lucadmin
            e.K. gefunden hast!
            </Trans>
          </h1>
          <p className={"mr-auto animated-text"}>
            {t("maintenance.text")}
          </p>
        </div>
      </div>
      <div className={"hero-image h-full w-full flex flex-col md:h-screen"}>
        <Lazyload
            className={"hero-cutoff after:bg-none w-full h-full bg-clip-border bg-cover overflow-hidden md:after:bg-white"}>
        </Lazyload>
      </div>
    </div>
  </div>
  )
}

export default MaintenanceScreen;