import MaintenanceScreen from "../screens/Maintenance";
import Esports from "../screens/Esports";

const App = () => {

  switch (window.location.host.split(".")[0]) {
    case "esports":
      return <Esports/>
    default:
      return <MaintenanceScreen/>;
  }

}

export default App;