import {BrowserRouter, Route, Routes} from "react-router-dom";
import Player from "./Player";
import PlayerOverview from "./PlayerOverview";
import EsportsPreRelease from "./EsportsPreRelease";
import Impressum from "./Impressum";
import {Helmet} from "react-helmet";

const Esports = () => {
  return (
      <div>
        <Helmet>
          <title>Lucadmin esports | Valorant played professional</title>
          <meta
              name="description"
              content="Lucadmin esports ist ein professionelles Valorant esports-Team. Finde hier alles über uns heraus!"
          />
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<EsportsPreRelease/>}/>
            <Route path="players" element={<PlayerOverview/>}/>
            <Route path="players/:playername" element={<Player/>}/>
            <Route path="/impressum" element={<Impressum/>}/>
          </Routes>
        </BrowserRouter>
      </div>
  )
}

export default Esports;