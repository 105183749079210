import React from 'react';

function PlayerHeading({text}) {
  return (
      <div className={"w-full flex flex-row"}>
        <div className={"h-0.5 w-10 bg-white"} style={{marginTop: "0.45rem"}}/>
        <p className={"text-3xl flex-none -mx-0.5"}>{text}</p>
        <div className={"h-0.5 w-full bg-white mt-3"}/>
      </div>
  );
}

export default PlayerHeading;