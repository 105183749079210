import AlternateTimeline from "../components/AlternateTimeline";
import Logo from "../components/Logo";
import Countdown from "react-countdown";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const EsportsPreRelease = () => {
  return (
      <div>
        <Helmet>
          <title>Prerelease Timeline | Lucadmin esports</title>
          <meta
              name="description"
              content="Finde hier alle Informationen bezüglich unserer Timeline bis zum Release der offiziellen Lucadmin esports Seite"
          />
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://esports.lucadmin.de/"/>
          <meta name="og:title" content="Prerelease Timeline | Lucadmin esports"/>
          <meta name="og:description" content="Finde hier alle Informationen bezüglich unserer Timeline bis zum Release der offiziellen Lucadmin esports Seite"/>
          <meta name="og:image" content="%PUBLIC_URL%/hero-image.webp"/>
          <meta name="og:image:alt" content="Lucadmin 3D-Logo-Image"/>
        </Helmet>
        <div className={
          "p-8 md:p-16 flex justify-center items-center flex-col text-center bg-darkgray text-white"
        }>
          <div className={"w-full flex flex-row items-center justify-center"}>
            <div className={"hidden h-0.5 bg-white w-full grow md:flex"}/>
            <div className={"h-20 w-1/2 grow-0 mx-3"}><Logo sub={"esports"}/></div>
            <div className={"hidden h-0.5 bg-white w-full grow md:flex"}/>
          </div>
          <p className={"my-6 md:mx-20"}>
            Diesen Winter haben wir Lucadmin esports als Teil von Lucadmin e.K. gegründet,
            um uns gemeinsam mit engagierten Spielern auf den Weg zu einer professionellen esports-Karriere zu machen.
            <br/><br/>Wir sind aktuell daran die vollständige Lucadmin esports-Seite vorzubereiten...</p>
          <div className={"mb-3"}>
            <p className={"mb-1"}>Erfahre in der Zeit hier unseren Plan für dieses Jahr. Für Änderungen schaue gerne
              regelmäßig mal rein.</p>
            <h2 className={"font-semibold text-lg"}>Timeline für Dezember</h2>
          </div>
          <div className={"timeline block"}>
            {/*<Timeline/>*/}
            <AlternateTimeline/>
          </div>
          <Countdown
              date={"2022-01-07T00:00:00"}
              precision={3}
              renderer={props => <div className={"mt-10"}>
                <p>Noch
                  <span className={"text-3xl font-bold"}> {props.days} </span>
                  Tage,
                  <span className={"text-3xl font-bold"}> {props.hours} </span>
                  Stunden und
                  <span className={"text-3xl font-bold"}> {props.minutes} </span>
                  Minuten bis wir die neue Lucadmin esports Website veröffentlichen
                </p>
              </div>}
          />
          <div className={"flex flex-row mt-10"}>
            <p>Lucadmin e.K. - <Link className={"inline-block underline"} to={"/impressum"}>Impressum</Link></p>
          </div>
        </div>
      </div>
  )
}

export default EsportsPreRelease;