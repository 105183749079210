import * as ReactDOM from "react-dom";
import App from "./components/App";

//STYLING
import './index.css';
import "./styles/animation.css"
import "./styles/hero.css"

//UTILS
import './utils/i18n';

ReactDOM.render(<App/>, document.getElementById("root"));