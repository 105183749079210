import {useParams} from "react-router-dom";
import Quote from "../components/Quote";
import duellistIcon from "../res/icons/roleSymbols/DuelistClassSymbol.png"
import corsshairImage from "../res/images/crosshair-template.png"
import PlayerHeading from "../components/PlayerHeading";
import Logo from "../components/Logo";
import "../styles/textstroke.css";

const Player = () => {

  const params = useParams()

  return (
      <div className={"h-full w-full bg-darkgray text-white"}>
        <div className={"w-full flex flex-row justify-start p-5"}>
          <Logo sub={"esports"}/>
        </div>
        <div className={"h-full w-full flex flex-row flex-wrap-reverse md:flex-nowrap"}>
          <div className={"basis-2/3 shrink"}>
            <PlayerHeading text={"Zum Spieler"}/>
            <h2>Luca I.</h2>
            <div>

            </div>
            <h3>Alter: <span>19</span></h3>
            <h3>Teammitglied seit: <span>01.01.2022</span></h3>
            <Quote
                text={"Liberis cadunt in berolinum! Est salvus classis, cesaris. Neuter, alter habenas aegre magicae de azureus, flavum abactor."}/>
          </div>
          <div className={"basis-1/3"}>
            <h1 className={"text-7xl font-bold text-transparent text-stroke-white"}>{params.playername}</h1>
          </div>
        </div>
          <PlayerHeading text={"Zu Valorant"}/>
          <div className={"flex flex-row"}>
            <h3>Main Team-Rolle: </h3>
            <img src={duellistIcon} alt={"Duellist role symbol"} className={"h-5 mx-2"}/>
            <p>Duellist</p>
          </div>
          <img src={corsshairImage} alt={"Preview of players Corsshair"}/>
          <h3>edpi: 200</h3>
          <h3>Resolution: 1440x1080 (4:3)</h3>
      </div>
  )
}

export default Player;