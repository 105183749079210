import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: true,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
        en: {
          translation: {
            maintenance: {
              title: "Hi. <br/> Welcome to Lucadmin e.K.",
              text: "Unfortunately, our website ist currently unavailable due to ongoing maintenance.\nHowever, you can already look forward to the new Lucadmin.de page!"
            }
          }
        },
        de: {
          translation: {
            maintenance: {
              title: "Hi. <br/> Schön, dass du zu Lucadmin\ne.K. gefunden hast!",
              text: "Leider ist die Website vorübergehend aufgrund von Wartungsarbeiten nicht verfügbar.\nAllerdings kannst du dich jetzt schon auf die neue Lucadmin.de Seite freuen!"
            }
          }
        }
      }
    });