import Timeline from '@mui/lab/Timeline';
import EsportsTimelineItem from "./EsportsTimelineItem";

export default function AlternateTimeline() {
  return (
      <Timeline position="alternate">
        <EsportsTimelineItem
            title={"Ideenfindung"}
            text={"Am 06.12. kam die Idee eines esports-Teams unter Lucadmin e.K. auf. Es soll die deutsche Valorantszene durch Professionalität und Effizienz erobern. Dafür mussten einige Pläne geschmiedet werden..."}
            date={"06.12.2021"}
        />
        <EsportsTimelineItem
            title={"Umstrukturierung von Lucadmin e.K."}
            text={"Um eine solche Unternehmung möglich zu machen, musste die bisher geplant Unternehmensstrukur verworfen werden. Schnell entstand der Plan Lucadmin nun in viere unterschiedliche Bereich zu unterteilen, die 2022 über das Jahr verteilt veröffentlicht werden. Lucadmin esports als Bereich wurde gegründet"}
            date={"07.12.2021"}
        />
        <EsportsTimelineItem
            title={"Teamaufstellung"}
            text={"Die ersten Ideen kamen zustande, aber alleine war es nicht machbar. Dementsprechend entstand ein Team. Das zukünftige Managementteam."}
            date={"08.12.2021"}
        />
        <EsportsTimelineItem
            title={"Beginn der Spielersuche"}
            text={"Und schon ging es auch los Spieler zu suchen. Stück für Stück wurden verschiedene Talente angeschrieben und erste Calls ausgemacht."}
            date={"09.12.2021"}
        />
        <EsportsTimelineItem
            title={"Bewerbungsphase"}
            text={"Mit jedem vermeintlichen Teammitglied gab es ein gesondertes Bewerbungsgespräch, in dem nicht nur unsere sondern auch deren Fragen gestellt und beantwortet wurden. Dadurch konnten wir uns einerseits schnell ein Bild machen, wer zu uns passt, andererseits entstand eine gute Vorstellung, was die Spieler benötigen und wie schnell wie diese Ressourcen bereitstellen sollten. Die Ergebnisse jedes Gespräches wurden gesammelt und nachbesprochen."}
            date={"10. - 27.12.2021"}
        />
        <EsportsTimelineItem
            title={"Entscheidungsturnier"}
            text={"Nachdem die Bewerbungsphase um ist, wird es ein Turnier geben, in dem über 2 Tage jeder Spieler sowohl seine Hardskills als auch seine Softskills unter Beweis stellen kann. Gespielt wird in regelmäßig gemischten 5er Squads, sodass jeder Spieler mit allen anderen mindestens einmal gespielt hat."}
            date={"27. & 28.12.2021"}
        />
        <EsportsTimelineItem
            title={"Entscheidung"}
            text={"Bis Anfang nächsten Jahres wird das Team dann feststehen. Die 6 Spieler bekommen dann per Post alle nötigen weiteren Informationen. Ob wir aus den restlichen Bewerbern weitere Teams bilden, steht im Moment noch nicht fest und hängt von der Größe des Managementteams zu diesem Zeitpunkt ab."}
            date={"01.01.2021"}
        />
        <EsportsTimelineItem
            title={"Lets go Lucadmin esports"}
            text={"Am 07.01.2022 werden wir zusammen mit der Veröffentlichung der offiziellen Teamzusammenstellung auch die neue Website feiern können."}
            date={"07.01.2021"}
        />
      </Timeline>
  );
}